@tailwind base;
@tailwind components;
@tailwind utilities;

/* disable globally if required */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance:textfield;
}

input[type=number] {
    -moz-appearance:textfield;
}

/* restore to hidden until mouse over */
.show_spinner input[type='number']::-webkit-outer-spin-button,
.show_spinner input[type='number']::-webkit-inner-spin-button {
   -webkit-appearance: inner-spin-button;
}

/* restore and show permanently */
.show_always input[type='number']::-webkit-outer-spin-button,
.show_always input[type='number']::-webkit-inner-spin-button {
   -webkit-appearance: inner-spin-button;
   opacity: 1;
   margin-left: 10px;
}

/* custome scrollbar */

#customeScrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 0 10px 10px 0;
	background-color: #F5F5F5;
}

#customeScrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#customeScrollbar::-webkit-scrollbar-thumb
{
	background-color: rgba(107, 114, 128);
  border-radius: 0 10px 10px 0;
}

/*select {
	-webkit-appearance: none
}*/

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.productImg {
  height: 180px;
  width: 180px;
}

.sidebarNav {
	height: calc(100vh - 72px);
}

/* Skeletenbox */
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
/* Skeletenbox end */

.categoryImg {
	width: 300px;
	height: 120px;
}

.dropdown:hover > .dropdown-content {
	display: block;
}

.min-h-8 {
	height: 2rem;
}
